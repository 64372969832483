<template>
  <div class="login-page vh-100">
    <video loop autoplay muted id="vid">
      <source src="img/bg.mp4" type="video/mp4" />
      <source src="img/bg.mp4" type="video/ogg" />
      Your browser does not support the video tag.
    </video>
    <div style="margin-top: -68px" class="d-flex align-items-center justify-content-center vh-100">
      <div class="px-5 col-md-6 ml-auto">
        <div class="px-5 col-10 mx-auto">
          <h2 class="text-dark my-0">Welcome Back</h2>
          <p class="text-50">Sign in to continue</p>
          <div class="mt-5 mb-4" id="login-form">
            <div class="form-group">
              <label for="email" class="text-dark">Email</label>
              <input
                v-model="email"
                type="email"
                placeholder="Enter Email"
                class="form-control"
                aria-describedby="emailHelp"
              />
            </div>
            <div class="form-group">
              <label for="password" class="text-dark">Password</label>
              <input
                v-model="password"
                type="password"
                placeholder="Enter Password"
                class="form-control"
              />
            </div>
            <button v-on:click="login" class="btn btn-primary btn-lg btn-block">
              SIGN IN
            </button>
            <!-- <div class="py-2">
              <a class="btn btn-lg btn-facebook btn-block">
                <i class="feather-facebook"></i> Connect with Facebook
              </a>
            </div> -->
          </div>
          <a href="forgot_password" class="text-decoration-none">
            <p class="text-center">Forgot your password?</p>
          </a>
          <div class="d-flex align-items-center justify-content-center">
            <a href="signup">
              <p class="text-center m-0">Don't have an account? Sign up</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      email: null,
      password: null,
      responseAvailable: false,
    };
  },
  methods: {
    ...mapActions(["getUser"]),
    login() {
      fetch(
        this.$store.state.baseURL +
          "/api/login?email=" +
          this.email +
          "&password=" +
          this.password,
        {
          method: "POST",
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log(
              "Server returned " + response.status + " : " + response.statusText
            );
          }
        })
        .then((response) => {
          if (response.response) {
            this.setCurUser(response.data);
          } else {
            if (response.message) alert(response.message);
            if (response.messages) {
              alert(response.messages);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setCurUser(result) {
      if (result) {
        this.$store.commit("setUser", result);
        window.localStorage.setItem("jwt", result.token);
        window.localStorage.setItem("curUser", JSON.stringify(result));
        window.location = "home";
        //this.$router.push("home");
      }
    },
  },
  computed: {
    ...mapState(["curUser"]),
  },
  beforeMount() {
    window.localStorage.removeItem("jwt");
    window.localStorage.removeItem("curUser");
  },
  mounted() {
    this.$store.dispatch("getUser");
  },
};
</script>